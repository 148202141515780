import { Alert, Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { BasePanel } from '@/components/Home/BasePanel';
import { secondsToMilliseconds } from 'date-fns';
import { ContactFormContent } from '@/components/ContactForm';
import Button from '@/components/Button';
import useToggle from '@/hooks/useToggle';
import { useEffect, useState } from 'react';
import { getConfig } from '@/config';

const product = getConfig().defaultProduct;
const ContactPanel = () => {
  const { t } = useTranslation(['common', 'home']);
  const [formSent, toggleFormSent] = useToggle();
  const [isLoading, toggleLoading] = useToggle();
  const [formKey, setFormKey] = useState(Math.random().toString());

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (formSent) {
      timer = setTimeout(() => {
        toggleFormSent();
      }, secondsToMilliseconds(3));
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [formSent]);

  const handleSubmit = () => {
    toggleFormSent();
    setFormKey(Math.random().toString());
  };

  return (
    <BasePanel id="contact">
      <div id="home_container_5" />
      <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'flex-start',
              maxWidth: 600,
              gap: 5,
            }}
          >
            <Typography variant="h4" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
              {t('home:container_5_title')}
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
              {t('home:container_5_subtitle', { product: t(`common:${product}_product`) })}
            </Typography>
            <ContactFormContent key={formKey} onLoading={toggleLoading} onSubmit={handleSubmit} hideAttachment />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Button variant="contained" size="large" form="contact_form" type="submit" loading={isLoading}>
                {t('common:submit')}
              </Button>
              {formSent && (
                <Alert variant="outlined" severity="success" sx={{ paddingBlock: 0.5 }}>
                  {t('home:contact_form_submitted')}
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </BasePanel>
  );
};

export default ContactPanel;
