import { Box, Container, styled, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Button from '@/components/Button';
import Card from '@/components/Home/HowWorksPanel/Card';
import { BasePanel } from '@/components/Home/BasePanel';
import { HEADER_HEIGHT } from '@/components/Home/Header';
import { getConfig } from '@/config';
import YoutubeCard from '@/components/Home/HowWorksPanel/YoutubeCard';

const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(20)};

  .video-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${({ theme }) => theme.spacing(6)};
    row-gap: ${({ theme }) => theme.spacing(22)};
  }

  .mobile-video-grid {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(1, 0.7fr);
    column-gap: ${({ theme }) => theme.spacing(6)};
    row-gap: ${({ theme }) => theme.spacing(6)};
  }

  ${({ theme }) => theme.breakpoints.down('xl')} {
    padding-block-start: ${HEADER_HEIGHT}px;
    gap: ${({ theme }) => theme.spacing(12)};
    .video-grid {
      row-gap: ${({ theme }) => theme.spacing(14)};
    }
  }
`;

const product = getConfig().defaultProduct;

const HowWorksPanel = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation(['common', 'home']);
  return (
    <BasePanel id="how" sx={{ height: isMobile ? 'auto' : '100vh' }}>
      <div id="home_container_4" />
      <StyledContainer maxWidth="xl">
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Typography variant="h4" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_4_title')}
          </Typography>
          <Typography variant="body2" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_4_subtitle', { product: t(`common:${product}_product`) })}
          </Typography>
        </Box>
        <div className={!isMobile ? 'video-grid' : 'mobile-video-grid'}>
          <YoutubeCard title={t('home:container_4_video_1', { product: t(`common:${product}_product`) })} videoid="HJrWGZXpIvs" />
          <YoutubeCard title={t('home:container_4_video_2')} videoid="Z0Pg4QrKOB4" params="&t=3s" />
          <YoutubeCard title={t('home:container_4_video_3')} videoid="akBiUDRypPs" />
          <YoutubeCard title={t('home:container_4_video_4', { product: t(`common:${product}_product`) })} videoid="tdlUJs3lVDs" />
          <YoutubeCard title={t('home:container_4_video_5')} videoid="JLbrQgFAYmo" />
          <YoutubeCard
            title={t('home:container_4_video_6')}
            videoid="SGvjV-hq4CA"
            params="start=822&ab_channel=ESGRS"
            background="/images/video_placeholder.avif"
          />
        </div>
        {/* <Button variant="contained" size="large">
          {t('home:view_all_videos')}
        </Button>*/}
      </StyledContainer>
    </BasePanel>
  );
};

export default HowWorksPanel;
