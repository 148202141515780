import { FC, ReactNode } from 'react';
import { Box, Paper, styled, Typography } from '@mui/material';

const Ellipse = styled('div')`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${({ theme }) => `linear-gradient(in oklab 140deg, ${theme.vars.palette.secondary.main} -10.02%, transparent  81.1%)`};
  ${({ theme }) => theme.getColorSchemeSelector('aid')} {
    background: ${({ theme }) => `linear-gradient(in oklab 140deg, #727A8A -10.02%, transparent  81.1%)`};
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  text-wrap: balance;
  min-height: 50px;
  display: flex;
  align-items: center;

  @supports not (text-wrap: balance) {
    white-space: pre-line;
  }
`;

interface Props {
  icon: ReactNode;
  title: string;
  description: string;
}

const Card: FC<Props> = ({ icon, title, description }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: -50, left: 'calc(50% - 50px)' }}>
        <Ellipse>{icon}</Ellipse>
      </Box>
      <Paper
        elevation={1}
        sx={{
          borderRadius: '12px',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          paddingBlockStart: 14,
          paddingBlockEnd: 6,
          paddingInline: 5,
          textAlign: 'center',
        }}
      >
        <StyledTypography variant="h7" color="secondary">
          {title}
        </StyledTypography>
        <Typography variant="body2" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
          {description}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Card;
