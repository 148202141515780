import { Box, Container, styled, Typography } from '@mui/material';
import Button from '@/components/Button';
import GiqLogo from '@/assets/svg/logo_white.svg';
import Planet from '@/assets/svg/Group 1735.svg';
import Trends from '@/assets/svg/Group 1736.svg';
import Organization from '@/assets/svg/Group 1737.svg';
import Workflow from '@/assets/svg/Group 1738.svg';
import Image from 'next/image';
import { BasePanel } from '@/components/Home/BasePanel';
import { useTranslation } from 'next-i18next';
import Link from '@/components/Link';
import { getConfig } from '@/config';
import AidLogo from '@/assets/svg/aid_logo.svg';
import React, { FC } from 'react';
import useIsMobile from '@/hooks/useIsMobile';

const Panel = styled(BasePanel)`
  img {
    ${({ theme }) => theme.getColorSchemeSelector('aid')} {
      mix-blend-mode: luminosity;
    }
  }
  .aboutPanelContainer {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    gap: ${({ theme }) => theme.spacing(6)};
  }
  #about-list {
    svg {
      path {
        fill: ${({ theme }) => theme.vars.palette.secondary.main};
      }
      .Group_1735_svg__gradient_stop,
      .Group_1736_svg__gradient_stop,
      .Group_1737_svg__gradient_stop,
      .Group_1738_svg__gradient_stop {
        stop-color: ${({ theme }) => theme.vars.palette.secondary.main};
        ${({ theme }) => theme.getColorSchemeSelector('aid')} {
          stop-color: #727a8a;
        }
      }
    }
  }
`;

const product = getConfig().defaultProduct;

const AboutPanel: FC = () => {
  const { t } = useTranslation(['common', 'home']);
  const isMobile = useIsMobile();
  return (
    <Panel id="about" sx={{ height: isMobile ? 'auto' : '100vh' }}>
      <div id="home_container_2" />
      <Container maxWidth="xl" className={!isMobile ? 'aboutPanelContainer' : ''}>
        <Box sx={{ gridColumnStart: 1, gridColumnEnd: 6, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 5 }}>
          {product === 'aid' ? <AidLogo width={120} height={70} viewBox="0 0 48 28" /> : <GiqLogo viewBox="0 0 120 65" />}
          <Typography variant="h4" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_2_title')}
          </Typography>
          <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_2_description', { product: t(`common:${product}_product`) })}
          </Typography>
          <Link href="#features">
            <Button size="large" variant="contained">
              {t('home:browse_features')}
            </Button>
          </Link>
        </Box>
        <Box
          id="about-list"
          sx={{ gridColumnStart: 6, gridColumnEnd: 9, display: 'grid', gridTemplateColumns: '100px 1fr', gap: 6, alignItems: 'center' }}
        >
          <Planet viewBox="0 0 100 100" />
          <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_2_info_1')}
          </Typography>
          <Trends viewBox="0 0 100 100" />
          <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_2_info_2')}
          </Typography>
          <Organization viewBox="0 0 100 100" />
          <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_2_info_3')}
          </Typography>
          <Workflow viewBox="0 0 100 100" />
          <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
            {t('home:container_2_info_4')}
          </Typography>
        </Box>
        <Box
          sx={{
            gridColumnStart: 9,
            gridColumnEnd: 13,
            aspectRatio: 1,
            position: 'relative',
          }}
        >
          <Image src="/images/ground_cuts.avif" alt="ground cut" fill sizes="500px" />
        </Box>
      </Container>
    </Panel>
  );
};

export default AboutPanel;
