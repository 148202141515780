import { Box, BoxProps, styled } from '@mui/material';

export const BasePanel = styled(({ className, ...props }: BoxProps) => <Box className={'home_container' + ' ' + className} {...props} />)`
  height: 100vh;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;

  #home_container_1 {
    mix-blend-mode: screen;
    height: 90%;
    width: 90%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  #home_container_2 {
    mix-blend-mode: screen;
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    left: 50%;
    opacity: 0.2;
  }
  #home_container_3 {
    mix-blend-mode: luminosity;
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    left: 0;
  }
  #home_container_4 {
    mix-blend-mode: screen;
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    left: 50%;
    opacity: 0.2;
  }
  #home_container_5 {
    mix-blend-mode: lighten;
    height: 100%;
    width: 100%;
    position: absolute;
    left: -5%;
    bottom: 0;
    opacity: 0.5;
  }
`;
