import { Box, Container, NoSsr, styled } from '@mui/material';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { PageWithLayout } from '@/pages/_app';
import Header from '@/components/Home/Header';
import HomePanel from '@/components/Home/HomePanel';
import AboutPanel from '@/components/Home/AboutPanel';
import FeaturesPanel from 'src/components/Home/FeaturesPanel';
import HowWorksPanel from 'src/components/Home/HowWorksPanel';
import ContactPanel from '@/components/Home/ContactPanel';
import Footer from '@/components/Home/Footer';
import Image from 'next/image';
import { useRef } from 'react';
import { getConfig } from '@/config';
import useIsMobile from '@/hooks/useIsMobile';
import { theme } from '@/theme';

export async function getServerSideProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'home', 'space_academy_enrollment'])),
      // Will be passed to the page component as props
    },
  };
}

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{
  isMobile?: boolean;
}>`
  height: 100vh;
  width: 100%;
  scroll-snap-type: ${({ isMobile }) => (isMobile ? 'unset' : 'y mandatory')};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.vars.palette.background.default};

  #cloud_1 {
    position: absolute;
    width: 658px;
    height: 391px;
    inset-inline-end: 0;
    top: calc(100vh - (391px / 2.5));
    z-index: 1;
  }
  #cloud_2 {
    position: absolute;
    width: 658px;
    height: 391px;
    inset-inline-start: 0;
    top: calc(200vh - (391px / 2.5));
    z-index: 1;
  }
  #cloud_3 {
    position: absolute;
    width: 514px;
    height: 480px;
    inset-inline-end: 0;
    top: calc(300vh - (514px / 2.5));
    z-index: 1;
  }
  .mobile_bg {
    background: rgba(1, 1, 1, 0.8);
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .footer {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: calc(500vh - 93px);
    z-index: 1;
  }
`;

const Home: PageWithLayout = () => {
  const isMobile = useIsMobile();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  return (
    <Root ref={scrollableContainerRef} data-mui-color-scheme={getConfig().defaultColorScheme} isMobile={isMobile}>
      <Box
        className={isMobile ? 'mobile_bg' : ''}
        sx={{
          width: '100%',
          height: 'fit-content',
          position: 'fixed',
          top: 0,
          zIndex: 2,
          paddingInlineStart: '1px',
          paddingInlineEnd: 4,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            height: 'fit-content',
            paddingBlockStart: 9,
          }}
        >
          <NoSsr>
            <Header scrollableContainerRef={scrollableContainerRef} />
          </NoSsr>
        </Container>
      </Box>
      <HomePanel />
      <div id="cloud_1">
        <Image src="/images/cloud_1.avif" alt="Cloud 1" width={658} height={391} priority />
      </div>
      <AboutPanel />
      <div id="cloud_2">{!isMobile && <Image src="/images/cloud_2.avif" alt="Cloud 1" width={658} height={391} />}</div>
      <FeaturesPanel />
      <div id="cloud_3">{!isMobile && <Image src="/images/satellite_2.avif" alt="Satellite" width={514} height={480} />}</div>
      <HowWorksPanel />
      <ContactPanel />
      <Box className={!isMobile ? 'footer' : ''}>
        <Container
          maxWidth="xl"
          sx={{
            height: 'fit-content',
            zIndex: 1,
          }}
        >
          <Footer />
        </Container>
      </Box>
    </Root>
  );
};

Home.getLayout = (page) => page;
Home.title = 'home';

export default Home;
