import { Container, styled, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Button from '@/components/Button';
import { BasePanel } from '@/components/Home/BasePanel';
import Link from '@/components/Link';
import { useSession } from 'next-auth/react';
import { getConfig } from '@/config';
import useIsMobile from '@/hooks/useIsMobile';
import Banner from '@/components/Home/SpaceAcademy/Banner';

const BottomShadow = styled('div')`
  position: absolute;
  height: 15%;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => `linear-gradient(in oklab 180deg, transparent 0%, ${theme.vars.palette.background.default} 100%)`};
  ${({ theme }) => theme.getColorSchemeSelector('aid')} {
    background: linear-gradient(in oklab 180deg, rgba(18, 23, 35, 0) 0%, #121723 100%);
  }
`;

const product = getConfig().defaultProduct;

const HomePanel = () => {
  const { t } = useTranslation(['common', 'home']);
  const { status } = useSession();
  const isMobile = useIsMobile();
  return (
    <BasePanel id="home">
      {/* <Banner /> */}
      <div id="home_container_1" />
      <Container
        maxWidth="xl"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 4 }}
      >
        <Typography variant="h3" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
          {t('home:container_1_title')}
        </Typography>
        <Typography variant="h7" color="text.secondary" sx={{ whiteSpace: 'pre-line' }}>
          {t('home:container_1_subtitle')}
        </Typography>
        <Link href={status === 'authenticated' ? '/map' : '/login?callbackUrl=%2Fmap'} sx={{ marginBlockStart: 10 }}>
          {!isMobile && (
            <Button size="large" variant="contained">
              {t(`home:${status === 'authenticated' ? 'continue' : 'login'}`, { product: t(`common:${product}_product`) })}
            </Button>
          )}
        </Link>
      </Container>
      <BottomShadow />
    </BasePanel>
  );
};

export default HomePanel;
