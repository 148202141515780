import { Container, styled, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Button from '@/components/Button';
import GiqLogo from '@/assets/svg/logo_white.svg';
import AidLogo from '@/assets/svg/aid_logo.svg';
import Stack from '@/assets/svg/stack.svg';
import World from '@/assets/svg/World.svg';
import Workspaces from '@/assets/svg/Workspaces.svg';
import Satellite from '@/assets/svg/Satellite.svg';
import Reports from '@/assets/svg/Reports.svg';
import Precise from '@/assets/svg/Precise.svg';
import Card from '@/components/Home/FeaturesPanel/Card';
import { BasePanel } from '@/components/Home/BasePanel';
import Link from '@/components/Link';
import { HEADER_HEIGHT } from '@/components/Home/Header';
import { getConfig } from '@/config';
import { FC } from 'react';
import useIsMobile from '@/hooks/useIsMobile';

const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(20)};

  .features-title {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(3)};
  }

  .features-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${({ theme }) => theme.spacing(6)};
    row-gap: ${({ theme }) => theme.spacing(22)};
    z-index: 2;
  }
  .mobile-features {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    row-gap: ${({ theme }) => theme.spacing(22)};
  }

  ${({ theme }) => theme.breakpoints.down('xl')} {
    padding-block-start: calc(${HEADER_HEIGHT}px + ${({ theme }) => theme.spacing(3)});
    padding-block-end: ${({ theme }) => theme.spacing(4)};
    gap: 0;
    justify-content: space-between;

    .features-title {
      margin-block-end: ${({ theme }) => theme.spacing(14)};
    }

    .features-grid {
      row-gap: ${({ theme }) => theme.spacing(16)};
    }
  }
`;

const product = getConfig().defaultProduct;

const FeaturesPanel: FC = () => {
  const { t } = useTranslation(['common', 'home']);
  const isMobile = useIsMobile();
  return (
    <BasePanel id="features" sx={{ height: isMobile ? 'auto' : '100vh' }}>
      <div id="home_container_3" />
      <StyledContainer maxWidth="xl">
        <div className="features-title">
          <Typography variant="h4" color="text.primary" sx={{ whiteSpace: 'pre-line', zIndex: 1 }}>
            {t('home:container_3_title')}
          </Typography>
          {product === 'aid' ? <AidLogo width={120} height={70} viewBox="0 0 48 28" /> : <GiqLogo />}
        </div>
        <div className={!isMobile ? 'features-grid' : 'mobile-features'}>
          <Card
            icon={<Stack />}
            title={t('home:container_3_info_1_title')}
            description={t('home:container_3_info_1_description', { product: t(`common:${product}_product`) })}
          />
          <Card
            icon={<World />}
            title={t('home:container_3_info_2_title')}
            description={t('home:container_3_info_2_description', { product: t(`common:${product}_product`) })}
          />
          <Card
            icon={<Precise />}
            title={t('home:container_3_info_3_title')}
            description={t('home:container_3_info_3_description', { product: t(`common:${product}_product`) })}
          />
          <Card
            icon={<Workspaces />}
            title={t('home:container_3_info_4_title')}
            description={t('home:container_3_info_4_description', { product: t(`common:${product}_product`) })}
          />
          <Card icon={<Satellite />} title={t('home:container_3_info_5_title')} description={t('home:container_3_info_5_description')} />
          <Card
            icon={<Reports />}
            title={t('home:container_3_info_6_title')}
            description={t('home:container_3_info_6_description', { product: t(`common:${product}_product`) })}
          />
        </div>
        <Link href="#contact">
          <Button variant="contained" size="large">
            {t('home:request_demo')}
          </Button>
        </Link>
      </StyledContainer>
    </BasePanel>
  );
};

export default FeaturesPanel;
